var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Box, Button, Container, Dialog, LinearProgress, Tab, TablePagination, Tabs, useTheme, } from '@mui/material';
import axios from 'axios';
import { connect, useDispatch } from 'react-redux';
import Messages from '../../../../../../shared/helpers/errorMessages';
import { getEnvSettings } from '../../../../../../config/environmentSettings';
import showNotification from '../../../../../../shared/helpers/notifications';
import { composeErrorMessage } from '../../../../../../shared/helpers/interceptors';
import { CLOSE_ERROR_NOTICE } from '../../../../../../redux/actionTypes/apiErrorsActionTypes';
import ConfirmationDialog from '../../../../../../shared/components/confirmationDialog/ConfirmationDialog';
import useConfirmationDialog from '../../../../../../shared/components/confirmationDialog/useConfirmationDialog';
import InitiateSettlements from './../InitiateSettlements';
import ClientSettlementsTableBody from './ClientSettlementsBody';
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}
function mapSettlements(settlements) {
    return settlements.map((item) => {
        var _a, _b, _c, _d;
        return {
            clientCode: item.client_code,
            settlementCode: item.settlement_code,
            currency: item.currency_code,
            status: item.state,
            failedReason: item.failed_reason || '',
            commands: item.commands,
            collapsed: false,
            dateFrom: ((_a = item.data) === null || _a === void 0 ? void 0 : _a.records) && item.data.records[0].type === 'date_from' ? (_b = item.data) === null || _b === void 0 ? void 0 : _b.records[0].value : undefined,
            dateTo: ((_c = item.data) === null || _c === void 0 ? void 0 : _c.records) && item.data.records[1].type === 'date_to' ? (_d = item.data) === null || _d === void 0 ? void 0 : _d.records[1].value : undefined,
        };
    });
}
const ClientSettlementsTable = (props) => {
    const [initiateDialog, setInitiateDialog] = useState(false);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('status');
    const [totalItems, setTotalItems] = useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [settlements, setSettlements] = useState([]);
    const [statusFilter, setStatusFilter] = useState('created');
    const [loading, setLoading] = useState(true);
    const [settlemntsToExecute, setSettlemntsToExecute] = useState(null);
    const theme = useTheme();
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const { isConfirmOpen, openConfirmDialog, closeConfirmDialog } = useConfirmationDialog();
    const handlePageChange = (event, pageNumber) => {
        setPage(pageNumber);
    };
    const fetchData = () => __awaiter(void 0, void 0, void 0, function* () {
        return yield axios
            .get('settlements/accounts', {
            baseURL: getEnvSettings().adminApiUrl,
            params: {
                states: statusFilter,
                client_code: getEnvSettings().etoroClientId,
                limit: statusFilter !== 'created' ? rowsPerPage : null,
                page: statusFilter !== 'created' ? page + 1 : null,
                sort_by: statusFilter !== 'created' ? 'created_at' : null,
                sort_direction: statusFilter !== 'created' ? 'desc' : null,
            },
        })
            .then((response) => {
            setTotalItems(response.data.total || 0);
            return response.data.records;
        })
            .catch((e) => {
            if (e.message === 'canceled') {
                return;
            }
            const message = composeErrorMessage(e, Messages.SETTLEMENTS_FETCH);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    });
    const executeSettlement = (id) => {
        return axios
            .post(`/settlements/accounts/${id}/execute/`, null, {
            baseURL: getEnvSettings().adminApiUrl,
        })
            .catch((e) => {
            if (e.message === 'canceled') {
                return;
            }
            const message = composeErrorMessage(e, Messages.SETTLEMENTS_EXECUTE);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    const declineSettlement = (id) => {
        return axios
            .post(`/settlements/accounts/${id}/decline/`, null, {
            baseURL: getEnvSettings().adminApiUrl,
        })
            .catch((e) => {
            if (e.message === 'canceled') {
                return;
            }
            const message = composeErrorMessage(e, Messages.SETTLEMENTS_DECLINE);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    const manualExecute = (params) => {
        return axios
            .post(`/settlements/accounts/${params.settlementCode}/command/${params.commandCode}/manually_execute`, { reference_id: params.reference }, {
            baseURL: getEnvSettings().adminApiUrl,
        })
            .catch((e) => {
            if (e.message === 'canceled') {
                return;
            }
            const message = composeErrorMessage(e, Messages.SETTLEMENTS_DECLINE);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    const executeSettlements = (arr) => __awaiter(void 0, void 0, void 0, function* () {
        const ids = arr.map((item) => item.settlementCode);
        if (ids.length) {
            let n = 0;
            while (n < arr.length) {
                const id = ids[n];
                yield executeSettlement(id);
                n += 1;
            }
        }
    });
    const onManualExecute = (params) => __awaiter(void 0, void 0, void 0, function* () {
        yield manualExecute(params).finally(() => {
            getData();
        });
        showNotification({
            message: `Settlement was executed`,
            color: 'success',
            dispatch: errorNotice,
        });
    });
    const onExecuteSettlement = (id) => __awaiter(void 0, void 0, void 0, function* () {
        yield executeSettlement(id).finally(() => {
            getData();
        });
        showNotification({
            message: `Settlement was executed`,
            color: 'success',
            dispatch: errorNotice,
        });
    });
    const onDeclineSettlement = (id) => __awaiter(void 0, void 0, void 0, function* () {
        yield declineSettlement(id).finally(() => {
            getData();
        });
        showNotification({
            message: `Settlement was declined`,
            color: 'success',
            dispatch: errorNotice,
        });
    });
    const onExecuteAll = (settlements) => __awaiter(void 0, void 0, void 0, function* () {
        if (settlements) {
            yield executeSettlements(settlements).finally(() => {
                getData();
            });
            showNotification({
                message: `All settlements were executed`,
                color: 'success',
                dispatch: errorNotice,
            });
        }
    });
    const getData = () => __awaiter(void 0, void 0, void 0, function* () {
        let settlements = [];
        setLoading(true);
        yield fetchData().then((res) => {
            settlements = res;
        });
        setSettlements(mapSettlements(settlements));
        setLoading(false);
        return { settlements };
    });
    useEffect(() => {
        if (props.customersLoaded && !props.clientsLoading) {
            getData();
        }
    }, [statusFilter, page, rowsPerPage, props.selectedClient, props.customersLoaded]);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const visibleRows = React.useMemo(() => settlements, [order, orderBy, page, rowsPerPage, settlements]);
    const onFilterChange = (filter) => {
        setPage(0);
        setStatusFilter(filter);
    };
    const handleToggleDialog = (result) => {
        setInitiateDialog(false);
        if (result) {
            getData();
            setPage(0);
            setStatusFilter('created');
        }
    };
    return (React.createElement(Container, { maxWidth: 'xl' },
        React.createElement(Box, null,
            React.createElement(Box, { height: '5px' }, loading && React.createElement(LinearProgress, null)),
            React.createElement(Box, { mb: 2, sx: {
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderBottom: `2px solid ${theme.palette.background.default}`,
                } },
                React.createElement(Tabs, { value: statusFilter, onChange: (e, value) => onFilterChange(value), "aria-label": 'basic tabs example' },
                    React.createElement(Tab, { label: 'Created', value: 'created', disabled: loading }),
                    React.createElement(Tab, { label: 'Pending', value: 'pending', disabled: loading }),
                    React.createElement(Tab, { label: 'Failed', value: 'failed', disabled: loading }),
                    React.createElement(Tab, { label: 'Declined', value: 'declined', disabled: loading }),
                    React.createElement(Tab, { label: 'Success', value: 'success', disabled: loading })),
                statusFilter !== 'created' ? null : (React.createElement(Button, { size: 'small', variant: 'contained', onClick: () => setInitiateDialog(true), disabled: settlements.length > 0 }, "Initiate")),
                React.createElement(Dialog, { open: initiateDialog, onClose: () => setInitiateDialog(false), fullWidth: true },
                    React.createElement(InitiateSettlements, { toggleDialog: (e) => handleToggleDialog(e), withDates: true })))),
        loading ? null : (React.createElement(Box, null,
            React.createElement(React.Fragment, null, settlements.length ? (React.createElement(Box, { sx: { overflow: 'auto' } },
                React.createElement(ClientSettlementsTableBody, { rows: visibleRows, type: statusFilter, manualExecute: onManualExecute, executeSettlement: onExecuteSettlement, declineSettlement: onDeclineSettlement }))) : (React.createElement(Alert, { severity: 'info' }, "No settlements available"))),
            statusFilter !== 'created' && (React.createElement(TablePagination, { rowsPerPageOptions: [5, 10, 25, 1000], component: 'div', count: totalItems, rowsPerPage: rowsPerPage, page: page, onPageChange: handleChangePage, onRowsPerPageChange: handleChangeRowsPerPage })))),
        React.createElement(ConfirmationDialog, { title: 'Execute All Settlements', content: `Are you sure you want execute all settlements for customer: ${settlemntsToExecute === null || settlemntsToExecute === void 0 ? void 0 : settlemntsToExecute.customerName}?`, open: isConfirmOpen, onClose: () => {
                closeConfirmDialog();
                setSettlemntsToExecute(null);
            }, onConfirm: () => {
                onExecuteAll(settlemntsToExecute === null || settlemntsToExecute === void 0 ? void 0 : settlemntsToExecute.settlements);
                closeConfirmDialog();
                setSettlemntsToExecute(null);
            } })));
};
const stateToProps = (state) => {
    const { selectedClient, allClients, loading } = state.clientSearch;
    const { customers, loaded } = state.customersSearch;
    return {
        selectedClient,
        customers,
        customersLoaded: loaded,
        clients: allClients,
        clientsLoading: loading,
    };
};
export default connect(stateToProps)(ClientSettlementsTable);
